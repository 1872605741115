@import '../../styles/variables';
@import '../../styles/mixins';

footer {
  background-color: $color-dark-grey;
  color: white;
  padding: 3rem;
  margin-top: 2rem;
  height: 10rem;
}
