button {
  border: none;
  outline: none;
  cursor: pointer;
  transition: scale 0.2s ease-in;
  font-size: 1rem;
  color: inherit;
  &:active {
    transform: scale(1.07);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

input[type='text'] {
  outline: none;
  font-size: 1rem;
  padding: 0.5rem;
  text-transform: uppercase;
}
