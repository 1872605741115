@import '../../styles/variables';
@import '../../styles/mixins';

@keyframes pop {
  from {
    transform: scale((1.4));
  }
  to {
    transform: scale(1);
  }
}

.pop {
  animation: pop 0.3s ease-out;
}

.cart-btn {
  @include flex-center;
  background-color: $color-red;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  &__logo {
    fill: white;
    width: 1.5rem;
    height: 1.5rem;
    transform: translateX(-0.1rem);
  }
  &__num {
    color: white;
    position: absolute;
    background-color: $color-yellow;
    top: -0.4rem;
    right: -0.5rem;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    @include flex-center;
    font-size: 0.8rem;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  70% {
    transform: translateX(-5%);
  }
  100% {
    transform: 0;
  }
}

.cart {
  display: flex;
  flex-direction: column;
  background-color: $color-dark;
  color: $color-light-grey;
  position: fixed;
  right: 0;
  top: 0;
  padding: 2rem;
  height: 100vh;
  width: 100%;
  animation: slideIn 0.3s ease-out forwards;
  z-index: 1000;
  box-sizing: border-box;

  @include sm {
    width: 25vw;
    min-width: 20rem;
  }

  &-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  &__close {
    background-color: $color-dark;
    color: white;
    padding: 1.5rem;
    position: absolute;
    top: 0;
    right: 1rem;
    font-size: 1.5rem;
    z-index: 1000;
    @include sm {
      left: -3rem;
      right: auto;
      font-size: 1rem;
    }
  }

  &__header {
    @include flex-center;
    padding: 2rem 0;
    gap: 1rem;

    &__logo {
      position: relative;
      .cart-btn__logo {
        height: 2rem;
        width: 2rem;
      }
      .cart-btn__num {
        top: -1.4rem;
        right: -1rem;
      }
    }
  }

  &__items {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__footer {
    width: 100%;
    background-color: $color-dark;
    &__subtotal {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        text-transform: uppercase;
        color: grey;
      }
      h3 {
        color: $color-yellow;
        font-size: 1.5rem;
      }
    }
    button {
      width: 100%;
      background-color: black;
      color: white;
      text-transform: uppercase;
      font-size: 1rem;
      padding: 1rem 0;
      &:hover {
        background-color: $color-red;
      }
    }
  }
}
