@import '../../styles/variables';
@import '../../styles/mixins';

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: $color-grey;
  position: sticky;
  top: 0;
  z-index: 500;
  flex-direction: column;
  box-shadow: 0px 2px 6px -6px black;

  @include md {
    flex-direction: row;
    box-shadow: none;
    padding: 0 2rem;
  }

  &__menu-btn {
    text-transform: uppercase;
    padding: 1rem;
    background-color: transparent;
    @include sm {
      display: none;
    }
  }

  &__menu--mobile {
    display: block;
    @include sm {
      display: none;
    }
  }

  &__menu--desktop {
    display: none;
    @include sm {
      display: block;
    }
  }

  &__cart-container {
    position: fixed;
    right: 3rem;
    top: 1rem;
    @include md {
      position: initial;
    }
  }
}
