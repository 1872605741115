@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Breakpoints
$screen-sm-min: 640px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1280px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
