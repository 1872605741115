@import './styles/global';
@import './styles/variables';
@import './styles/mixins';

html {
  font-size: 14px;
  @include sm {
    font-size: 16px;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-grey;
  max-width: 1280px;
  margin: auto;
}
