@import '../../styles/variables';
@import '../../styles/mixins';

.product-card {
  position: relative;

  &__shipping-tag {
    background-color: black;
    color: white;
    position: absolute;
    z-index: 10;
    right: 0;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
  }
  &__img {
    &-container {
      @include flex-center;
      position: relative;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
      }
      @include sm {
        &.has-desc:hover::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
        }
      }
    }
    &-description {
      position: absolute;
      color: white;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 11rem;

    &-title {
      text-align: center;
      height: 2rem;
    }

    &-separator {
      width: 2rem;
      height: 4px;
      background-color: $color-yellow;
    }
    &-price {
      margin: 2rem 0;
      font-size: 1rem;
      &--dollars {
        font-weight: 700;
        font-size: 1.5rem;
        padding-left: 0.5rem;
      }
    }
  }
  &__actions {
    &_add {
      background-color: black;
      color: white;
      width: 100%;
      padding: 1rem 0;
      font-size: 1rem;
      &:hover {
        background-color: $color-yellow;
      }
    }
  }
}
