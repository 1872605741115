@import '../styles/mixins';

.filter-container {
  grid-area: filter;
}

.products-container {
  grid-area: products;
}

.clothes-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include sm {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: 'filter products';
    min-height: 50vh;
  }
}
