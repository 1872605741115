@import '../../styles/mixins';

.product-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;

  @include md {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
  }
}
